import { useEffect, useState, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { getDecimals, getTokenBalance } from 'utils/erc20'
import { getCakeAddress } from 'utils/addressHelpers'
import { useBcetoBurn, useCetoBurn, useERC20 } from './useContract'
import useWeb3 from './useWeb3'
import { useFastRefresh } from '../contexts/RefreshContext/RefreshFastContext'
import { useSlowRefresh } from '../contexts/RefreshContext/RefreshSlowContext'
import { makeContract } from '../utils/contract'
import erc20 from '../config/abi/erc20.json'


const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account, library, chainId } = useWeb3()
  const { fastRefresh } = useFastRefresh()
  const tokenContract = makeContract(library, erc20, tokenAddress)


  const fetchBalance = useCallback(async () => {
    const res = await getTokenBalance(tokenContract, account)
    setBalance(new BigNumber(res))
  },[account])

  useEffect(() => {
    (async () => {
      if (tokenContract && account) {
        await fetchBalance()
      }
    })()
  }, [account, fastRefresh, chainId])

  return { balance, fetchBalance }
}

export const useTotalSupply = () => {
  const { slowRefresh } = useSlowRefresh()
  const [totalSupply, setTotalSupply] = useState<BigNumber>()
  const cakeContract = useERC20(getCakeAddress())

  useEffect(() => {
    async function fetchTotalSupply() {
      const supply = await cakeContract.methods.totalSupply().call()
      setTotalSupply(new BigNumber(supply))
    }

    if (cakeContract) fetchTotalSupply()
  }, [slowRefresh])

  return totalSupply
}

export const useBurnedBalance = () => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { slowRefresh } = useSlowRefresh()
  const cetoBurn = useCetoBurn()
  const bCeto = useBcetoBurn()

  useEffect(() => {
    const fetchBalance = async () => {
      const bal = await cetoBurn.methods.totalBurned().call()
      const totalBern = await bCeto.methods.totalBurned().call()

      setBalance(new BigNumber(bal).plus(totalBern))
    }

    if (cetoBurn) fetchBalance()
  }, [slowRefresh])

  return balance
}

export const useDecimals = (tokenAddress: string) => {
  const [decimals, setDecimals] = useState('18')
  const tokenContract = useERC20(tokenAddress)

  useEffect(() => {
    const fetch = async () => {
      setDecimals(await getDecimals(tokenContract))
    }

    if (tokenContract) fetch()
  }, [])

  return decimals
}

export default useTokenBalance
