import React from 'react'
import { toBN } from '../../utils/formaters'

export enum SLIPPAGE_ERROR {
  INVALID_INPUT = 'INVALID_INPUT',
  RISKY_LOW = 'RISKY_LOW',
  RISKY_HIGH = 'RISKY_HIGH',
}

export const SLIPPAGE_MESSAGE_ERROR = {
  [SLIPPAGE_ERROR.INVALID_INPUT]: 'Enter a valid slippage percentage',
  [SLIPPAGE_ERROR.RISKY_HIGH]: 'Your transaction may fail',
  [SLIPPAGE_ERROR.RISKY_LOW]: 'Your transaction may be frontrun',
}

export const SLIPPAGE_TOLERANCE_OPTIONS = [
  {
    value: '0.1',
  },
  {
    value: '0.5',
  },
  {
    value: '1',
  },
]

export const MIN_VALID_VALUE = 0

export const MAX_VALID_VALUE = 49.99

export const MIN_SUCCESS_VALID_VALUE = 0.5

export const MAX_DECIMALS_REGEX = /^\d*\.?\d{0,2}$/

export const INPUT_REGEX = RegExp('^\\d*(?:\\\\[.])?\\d*$')

export const DECIMAL_PATTERN_REGEX = /^(?!0\d)\d{0,2}(\.\d{1,2})?$/;
export const SPECIAL_CHARACTERS_REGEX = /[.*+?^${}()|[\]\\]/g;

export const DEFAULT_SLIPPAGE_PARAM = toBN(0.995)

export const SLIPPAGE_PARAM = 'slippage_param'

export const PERCENT_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      stroke="#BC0FC8"
      strokeLinecap="round"
      d="M18 18 6 6.049m3.614 10.048c0 .984-.8 1.782-1.789 1.782-.988 0-1.789-.798-1.789-1.782s.801-1.782 1.79-1.782c.987 0 1.788.798 1.788 1.782Zm8.35-8.315c0 .984-.801 1.782-1.79 1.782-.987 0-1.788-.798-1.788-1.782S15.186 6 16.175 6c.988 0 1.789.798 1.789 1.782Z"
    />
  </svg>
)
