import BigNumber from 'bignumber.js'
import erc20Masterchef from 'config/abi/erc20Masterchef.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getMasterChefAddress } from 'utils/addressHelpers'
import farmsConfig from 'config/constants/farms'
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID_MANTA

const fetchFarms = async () => {
  try {
    const data = await Promise.all(
      farmsConfig.map(async (farmConfig) => {
        const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
        const calls = [
          // Balance of token in the LP contract
          {
            address: farmConfig.tokenAddresses[CHAIN_ID],
            name: 'balanceOf',
            params: [lpAdress],
          },
          // Balance of quote token on LP contract
          {
            address: farmConfig.quoteTokenAdresses[CHAIN_ID],
            name: 'balanceOf',
            params: [lpAdress],
          },
          // Balance of LP tokens in the master chef contract
          {
            address: lpAdress,
            name: 'balanceOf',
            params: [getMasterChefAddress()],
          },
          // Total supply of LP tokens
          {
            address: lpAdress,
            name: 'totalSupply',
          },
          // Token decimals
          {
            address: farmConfig.tokenAddresses[CHAIN_ID],
            name: 'decimals',
          },
          // Quote token decimals
          {
            address: farmConfig.quoteTokenAdresses[CHAIN_ID],
            name: 'decimals',
          },
        ]

        let tokenAmount;
        let lpTotalInQuoteToken;
        let tokenPriceVsQuote;

        try {
          const [
            tokenBalanceLP,
            quoteTokenBlanceLP,
            lpTokenBalanceMC,
            lpTotalSupply,
            tokenDecimals,
            quoteTokenDecimals
          ] = await multicall(erc20Masterchef, calls);

          if (farmConfig.isTokenOnly) {
            tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
            if (
              (farmConfig.tokenSymbol.endsWith(QuoteToken.DAI) && farmConfig.quoteTokenSymbol.endsWith(QuoteToken.DAI)) ||
              (farmConfig.tokenSymbol.endsWith(QuoteToken.USDEX) && farmConfig.quoteTokenSymbol.endsWith(QuoteToken.DAI))
            ) {
              tokenPriceVsQuote = new BigNumber(1)
            } else {
              tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
            }
            lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
          } else {
            // Ratio in % a LP tokens that are in staking, vs the total number in circulation
            const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

            // Total value in staking in quote token value
            lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
              .div(new BigNumber(10).pow(quoteTokenDecimals))
              .times(new BigNumber(2))
              .times(lpTokenRatio)

            // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
            tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
            const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
              .div(new BigNumber(10).pow(quoteTokenDecimals))
              .times(lpTokenRatio)

            if (tokenAmount.comparedTo(0) > 0) {
              tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
            } else {
              tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
            }
          }

          const [info, totalAllocPoint, sharesPerSecond] = await multicall(masterchefABI, [
            {
              address: getMasterChefAddress(),
              name: 'poolInfo',
              params: [farmConfig.pid],
            },
            {
              address: getMasterChefAddress(),
              name: 'totalAllocPoint',
            },
            {
              address: getMasterChefAddress(),
              name: 'sharesPerSecond',
            },
          ])

          const allocPoint = new BigNumber(info[0][1].toString())
          const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

          return {
            ...farmConfig,
            tokenAmount: tokenAmount.toJSON(),
            lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
            tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
            poolWeight: poolWeight.toNumber(),
            multiplier: `${allocPoint.div(100).toString()}X`,
            sparkPerBlock: new BigNumber(sharesPerSecond).toNumber(),
          }
        } catch (error) {
          console.error(`Error fetching farm data: ${error}`)
          // Return a default object or handle error as needed
          return {
            ...farmConfig,
            tokenAmount: '0',
            lpTotalInQuoteToken: '0',
            tokenPriceVsQuote: '0',
            poolWeight: 0,
            multiplier: '0X',
            sparkPerBlock: 0,
          }
        }
      }),
    )
    return data
  } catch (error) {
    console.error(`Error fetching farm data: ${error}`)
    // Handle error at the top level if needed
    return []
  }
}

export default fetchFarms
