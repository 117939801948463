import farmsConfig from './farms'
import Token from "../../types/token";
//
const communityFarms = farmsConfig.filter((farm) => farm.isCommunity).map((farm) => farm.tokenSymbol)
const ENV_CHAIN_ID = String(process.env.REACT_APP_CHAIN_ID_MANTA) || '169'

export { farmsConfig, communityFarms, ENV_CHAIN_ID }
export { default as poolsConfig } from './pools'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const NATIVE_TOKEN = new Token(
  Number(ENV_CHAIN_ID),
  ZERO_ADDRESS,
  18,
  'ETH',
)


export const LP_DECIMALS = 18;
