import { CHAIN_ID } from './chain'

export default {
  masterChef: {
    [CHAIN_ID.MANTA]: '0x78343ABFC1381D4358161c2f73Fc0990CCD5cbE0',
  },
  zapper: {
    [CHAIN_ID.MANTA]: '',
  },
  mulltiCall: {
    [CHAIN_ID.MANTA]: '0x83E249f47d235120595a05B03557cFab5DA2Dfd2',
  },
  usdex: {
    [CHAIN_ID.MANTA]: '0x6Da9EbD271a0676F39C088a2b5fd849D5080c0af',
  },
  weth: {
    [CHAIN_ID.MANTA]: '0x0Dc808adcE2099A9F62AA87D9670745AbA741746',
  },
  usdc: {
    [CHAIN_ID.MANTA]: '0xb73603c5d87fa094b7314c74ace2e64d165016fb',
  },
  mft: {
    [CHAIN_ID.MANTA]: '0xF1DF0658b0442F76A31ce2f5C4f4D9D76352e271'
  },
  ceto: {
    [CHAIN_ID.MANTA]: '0x3af03e8c993900f0ea6b84217071e1d4cc783982'
  },
  cetoBurn: {
    [CHAIN_ID.MANTA]: '0x5E1b800f174357dC1D131eA08d42FdF1E5D18cd6'
  },
  stgai: {
    [CHAIN_ID.MANTA]: '0x25f0Beb8876859008d00d457fA2AE6493F0fE020'
  },
  manta: {
    [CHAIN_ID.MANTA]: '0x95cef13441be50d20ca4558cc0a27b601ac544e5'
  },
  bceto: {
    [CHAIN_ID.MANTA]: '0xe68874E57224D1e4E6D4C6B4cF5AF7Ca51867611'
  },
  stone: {
    [CHAIN_ID.MANTA]: '0xec901da9c68e90798bbbb74c11406a32a70652c3'
  }
}
