import { JsonRpcPayload, JsonRpcResponse } from 'web3-core-helpers'
import { http } from 'viem';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { CHAINS_RPC } from '../../config/constants/chain'

interface RequestArguments {
  method: string;
  params?: any;
  [key: string]: any;
}

interface AbstractProvider {
  sendAsync(
    payload: JsonRpcPayload,
    callback: (error: Error | null, result?: JsonRpcResponse) => void,
  ): void;
  send?(
    payload: JsonRpcPayload,
    callback: (error: Error | null, result?: JsonRpcResponse) => void,
  ): void;
  request?(args: RequestArguments): Promise<any>;
  connected?: boolean;
}


export function ethersWeb3ProviderFactory(chainId: number) {
  const provider: ExternalProvider = new CustomWeb3Provider(chainId);
  return new Web3Provider(provider);
}

export class CustomWeb3Provider implements AbstractProvider {
  rpc: any;

  constructor(private chainId: number) {
    this.buildRpc();
  }

  sendAsync(payload: any, callback: (error: Error, result?: JsonRpcResponse) => void): void {
    // const debug = (...args: any[]) => console.log('[CustomWeb3Provider]', `[${this.chainId}]`, `[${new Date().toISOString()}]`, ...args);
    // eslint-disable-next-line no-unused-expressions
    const debug = (...args: any[]) => { args; };
    debug('payload', payload);
    this.rpc.request(payload)
      .then((v: any) => {
        const result = {
          id: payload.id,
          jsonrpc: '2.0',
          result: v,
        };
        debug('response', result);
        return callback(undefined, result as any);
      })
      .catch((error) => {
        debug('failed', error);
        return callback(error);
      });
  }

  buildRpc() {
    const urls = CHAINS_RPC[this.chainId];
    const url = urls[0];
    const transport = http(url, {
      batch: {
        wait: 100,
      },
    });
    this.rpc = transport({});
  }
}
