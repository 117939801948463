import { useEffect, useMemo, useState } from 'react'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { useSelector, useDispatch } from 'react-redux'
import lpTokenAbi from 'config/abi/uni_v2_lp.json'
import { ENV_CHAIN_ID } from 'config/constants'
import { useDecimals } from 'hooks/useTokenBalance'
import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'
import { useFastRefresh } from '../contexts/RefreshContext/RefreshFastContext'
import { useSlowRefresh } from '../contexts/RefreshContext/RefreshSlowContext'
import useWeb3 from '../hooks/useWeb3'
import { makeContract } from '../utils/contract'


const ZERO = new BigNumber(0)

export const useFetchPublicData = (suspend: boolean) => {
  const dispatch = useDispatch()
  const { slowRefresh } = useSlowRefresh()
  useEffect(() => {
    if (!suspend) {
      dispatch(fetchFarmsPublicDataAsync())
    }
  }, [dispatch, suspend, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useFastRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const pid = 4 // eth/ceto
  const cetoPriceUsdc = usePriceCakeBusd()
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? cetoPriceUsdc.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceWethBusd = (): BigNumber => {
  const pid = 6 // WETH / DAI
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceCakeBusd = (): BigNumber => {
  const pid = 1 // CETO/USDEX+
  const usdexPriceUsdc = usePriceUsdexUsdc()

  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? usdexPriceUsdc.times(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceUsdexUsdc = (): BigNumber => {
  const pid = 0 // USDEX-USDC LP
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
}

export const usePriceMantaUsdc = (): BigNumber => {
  const pid = 2 // USDEX-USDC LP
  const bnbPrice = usePriceBnbBusd()

  // console.log('bnbPrice', bnbPrice.toString())
  const farm = useFarmFromPid(pid)
  return farm && farm.tokenPriceVsQuote ? bnbPrice.times(farm.tokenPriceVsQuote) : ZERO
}

export const useBalanceETH = (): BigNumber => {
  const [balance, setBalance] = useState<BigNumber>(ZERO)
  const { slowRefresh } = useSlowRefresh()
  const { account, library } = useWeb3()
  const web3 = new Web3(library.provider);


  useEffect(() => {
    const fetchBalance = async () => {
      setBalance(new BigNumber(await web3.eth.getBalance(account)))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, slowRefresh])

  return balance
}

export const useDisplayBalanceUSD = (pid: number, amount: BigNumber): string => {
  const { isTokenOnly, tokenPriceVsQuote, quoteTokenSymbol, quoteTokenAdresses } = useFarmFromPid(pid)
  const cetoPrice = usePriceCakeBusd()
  const lpInfo = useLpInfo(pid)
  const usdexPriceUsdc = usePriceUsdexUsdc()
  const wethPrice = usePriceBnbBusd()
  const mantaPriceUsdc = usePriceMantaUsdc()

  const decimals0 = Number(useDecimals(lpInfo.token0))
  const decimals1 = Number(useDecimals(lpInfo.token1))

  const result = useMemo(() => {
    function calcToken() {
      switch (quoteTokenSymbol) {
        case QuoteToken.DAI:
          return `$${amount.times(tokenPriceVsQuote).div(1e18).toFormat(2)}`
        default:
          return `${amount.times(tokenPriceVsQuote).div(1e18).toFormat(2)} ${String(quoteTokenSymbol)}`
      }
    }

    function calcLP() {
      switch (quoteTokenSymbol) {
        case QuoteToken.USDC:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve0)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals0)
                .toFormat(2)}`
            : `$${amount
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve1)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals1)
                .toFormat(2)}`
        case QuoteToken.WETH:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount
                .times(wethPrice)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve0)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals0)
                .toFormat(2)}`
            : `$${amount
                .times(wethPrice)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve1)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals1)
                .toFormat(2)}`
        case QuoteToken.CETO:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount
                .times(cetoPrice)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve0)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals0)
                .toFormat(2)}`
            : `$${amount
                .times(cetoPrice)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve1)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals1)
                .toFormat(2)}`
        case QuoteToken.MANTA:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount
                .times(mantaPriceUsdc)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve0)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals0)
                .toFormat(2)}`
            : `$${amount
                .times(mantaPriceUsdc)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve1)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals1)
                .toFormat(2)}`
        case QuoteToken.USDEX:
          return lpInfo.token1.toLowerCase() === quoteTokenAdresses[ENV_CHAIN_ID].toLowerCase()
            ? `$${amount
                .times(usdexPriceUsdc)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve0)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals0)
                .toFormat(2)}`
            : `$${amount
                .times(usdexPriceUsdc)
                .times(tokenPriceVsQuote)
                .times(lpInfo.reserve1)
                .times(2)
                .div(lpInfo.totalSupply)
                .div(10 ** decimals1)
                .toFormat(2)}`
        default:
          return `${amount
            .times(tokenPriceVsQuote)
            .times(lpInfo.reserve0)
            .times(2)
            .div(lpInfo.totalSupply)
            .div(10 ** decimals0)
            .toFormat(2)} ${String(quoteTokenSymbol)}`
      }
    }

    if (wethPrice && mantaPriceUsdc && cetoPrice && quoteTokenSymbol && lpInfo && quoteTokenAdresses) {
      return isTokenOnly ? calcToken() : calcLP()
    }

    return '$0'
  }, [
    isTokenOnly,
    amount,
    quoteTokenSymbol,
    tokenPriceVsQuote,
    wethPrice,
    mantaPriceUsdc,
    cetoPrice,
    lpInfo,
    quoteTokenAdresses,
    decimals0,
    decimals1,
  ])

  return result;
}

export interface LpInfo {
  totalSupply: BigNumber
  reserve0: BigNumber
  reserve1: BigNumber
  token0: string
  token1: string
}

export const useLpInfo = (pid: number): LpInfo => {
  const { library } = useWeb3();
  const { isTokenOnly, lpAddresses } = useFarmFromPid(pid)
  const lpAddress = lpAddresses[ENV_CHAIN_ID]
  const lpContract = makeContract(library, lpTokenAbi as any, lpAddress)
  const { slowRefresh } = useSlowRefresh()

  const [info, setInfo] = useState({
    totalSupply: ZERO,
    reserve0: ZERO,
    reserve1: ZERO,
    token0: '',
    token1: '',
  } as LpInfo)

  useEffect(() => {
    const fetch = async () => {
      try {
        const [totalSupply, reserves, token0, token1] = await Promise.all([
          lpContract.methods.totalSupply().call(),
          lpContract.methods.getReserves().call(),
          lpContract.methods.token0().call(),
          lpContract.methods.token1().call(),
        ])

        setInfo({
          totalSupply: new BigNumber(totalSupply.toString()),
          reserve0: new BigNumber(reserves._reserve0.toString()),
          reserve1: new BigNumber(reserves._reserve1.toString()),
          token0,
          token1,
        })
      } catch (e) {
        console.error(e)
      }
    }

    if (lpContract && !isTokenOnly && library) {
      fetch()
    }
  }, [isTokenOnly, slowRefresh, library])

  return info
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const ethPrice = usePriceBnbBusd()
  const cetoPrice = usePriceCakeBusd()
  const usdexPriceUsdc = usePriceUsdexUsdc()
  const mantaPriceUsdc = usePriceMantaUsdc()
  // const sparklerTVL = useTotalBalance().times(cakePrice).plus(useTotalBalanceLP().times(usePriceLP())).div(1e18)
  let value = ZERO

  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val
      if (farm.quoteTokenSymbol === QuoteToken.WETH) {
        val = ethPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.CETO) {
        val = cetoPrice.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.USDEX) {
        val = usdexPriceUsdc.times(farm.lpTotalInQuoteToken)
      } else if (farm.quoteTokenSymbol === QuoteToken.MANTA) {
        val = mantaPriceUsdc.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)
    }
  }
  return value
}
