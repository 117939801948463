import { NavEntry, SocialEntry } from 'sparkswap-uikit'

export const config: NavEntry[] = [
   {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Ceto404',
    href: 'https://exchange.cetoswap.com/#/ceto404',
  },
  {
    label: 'Trade',
    items: [
      {
        label: 'Exchange',
        href: `https://exchange.cetoswap.com/#/swap`,
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.cetoswap.com/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    href: '/farms',
  },
  {
    label: 'Bridge',
    href: 'https://exchange.cetoswap.com/#/bridge',
  },
  {
    label: 'Vaults',
    href: 'https://www.dexfinance.com/vaults?chainFilter=%5B169%5D'
  },
  {
    label: 'Marketplace',
    initialOpenState: true,
    items: [
      {
        label: 'Collection',
        href: `https://marketplace.cetoswap.com/collections/2`
      },
      {
        label: 'Dashboard',
        href: 'https://marketplace.cetoswap.com/dashboard'
      },
    ]
  },
  {
    label: 'Perpetuals',
    items: [
      {
        label: 'Perpetuals',
        href: `https://perpetuals.cetoswap.com`,
      },
      {
        label: 'SLP Vault',
        href: `https://perpetuals.cetoswap.com/vault`,
      },
      {
        label: 'Stats',
        href: `https://perpetuals.cetoswap.com/stats`,
      },
      {
        label: 'Daily Trades',
        href: `https://perpetuals.cetoswap.com/daily-trades`,
      },
    ],
  }
]

export const socials: SocialEntry[] = [
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/cetoswapmanta',
  },
  {
    label: 'Discord',
    icon: 'DiscordIcon',
    href: 'https://discord.gg/cetoswapmanta',
  },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/cetoswapmanta',
  },
]
