import type { AddEthereumChainParameter } from '@web3-react/types'

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }

export enum CHAIN_ID {
  MANTA = 169,
}

export const CHAINS_RPC = {
  [CHAIN_ID.MANTA]: ['https://pacific-rpc.manta.network/http'],
};


const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

export const DEFAULT_CHAIN_ID = CHAIN_ID.MANTA;

export const AVAILABLE_CHAINS_LIST = [
  CHAIN_ID.MANTA,
];

export const MAINNET_CHAINS: ChainConfig = {
  [CHAIN_ID.MANTA]: {
    urls: CHAINS_RPC[CHAIN_ID.MANTA],
    name: 'Manta Pacific Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://pacific-explorer.manta.network'],
  },
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {}
)
