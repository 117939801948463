import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'sparkswap-uikit'

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: black;
`

const Box = styled('div')`
  position: absolute;
  left: 50%;
  top: 22.5%;
  transform: translate(-50%, -50%);
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <Box>
        <Spinner />
      </Box>
    </Wrapper>
  )
}

export default PageLoader;
