import React, { Suspense, lazy, useMemo } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'

import { Buffer } from 'buffer';
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import Timer from './components/Timer'

const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})
window.Buffer = window.Buffer || Buffer;

export const TIMER = '1713906000' //  05.04.2024 15.00 UTC

const App: React.FC = () => {
  const { hash } = window.location
  const suspend = useMemo(() => hash && hash.endsWith('sparkler'), [hash])
  useFetchPublicData(suspend)

  return (
    <Router>
      <Timer remainTime={TIMER} />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
    </Router>
  )
}

export default React.memo(App)
