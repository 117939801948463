import React, { useState, useEffect, useContext } from 'react'

const FAST_INTERVAL = 10000

const RefreshFastContext = React.createContext({ fastRefresh: 0 })

export const useFastRefresh = () => useContext(RefreshFastContext)

const RefreshFastContextProvider = ({ children }) => {
  const [fast, setFast] = useState(0)


  useEffect(() => {
    const interval = setInterval(async () => {
      setFast((prev) => prev + 1)
    }, FAST_INTERVAL)
    return () => clearInterval(interval)
  }, [])


  return <RefreshFastContext.Provider value={{ fastRefresh: fast }}>{children}</RefreshFastContext.Provider>
}

export { RefreshFastContext, RefreshFastContextProvider }
