import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'
import { CHAIN_ID } from './chain'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'USDEX+/USDC',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0x3838753b9B9B7cf26ddc71d0F1a0Ee0b9144ABA5',
    },
    tokenSymbol: QuoteToken.USDEX,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.usdex[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    v2: true,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'CETO/USDEX+',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0xEF799451a28A1432712d19dF82e512d985D685D4',
    },
    tokenSymbol: QuoteToken.CETO,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.ceto[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.USDEX,
    quoteTokenAdresses: contracts.usdex,
    v2: true,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'WETH/MANTA',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0xB9454b5FC78C826cF028F4e513b0E0a4d2bF51a9',
    },
    tokenSymbol: QuoteToken.MANTA,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.manta[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    v2: true,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'STGAI/MANTA',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0x75791775DC127D4441ecc532be128214CA4c6f72',
    },
    tokenSymbol: QuoteToken.STGAI,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.stgai[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.MANTA,
    quoteTokenAdresses: contracts.manta,
    v2: true,
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'CETO/WETH',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0x6c72baEE6D7074E54ADD3f617ec5D6b22aFe779b',
    },
    tokenSymbol: QuoteToken.WETH,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.weth[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.CETO,
    quoteTokenAdresses: contracts.ceto,
    v2: true,
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'USDC/WETH',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0xd6359083da64e79ad7c54106ee71a889268f0462',
    },
    tokenSymbol: QuoteToken.WETH,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.weth[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
    v2: true,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'bCeto/WETH',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0x3afed5925034bb3b730ffdae6d98b6df45c0ff74',
    },
    tokenSymbol: QuoteToken.BCETO,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.bceto[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    v2: true,
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'STONE/WETH',
    lpAddresses: {
      [CHAIN_ID.MANTA]: '0x05db409bc3c2629e2987dc7aa87c39376bc49477',
    },
    tokenSymbol: QuoteToken.STONE,
    tokenAddresses: {
      [CHAIN_ID.MANTA]: contracts.stone[CHAIN_ID.MANTA],
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
    v2: true,
  },
]

export default farms
