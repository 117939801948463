export enum QuoteToken {
  'PLS' = 'PLS',
  'SPARK' = 'SPARK',
  'DAI' = 'DAI',
  'WETH' = 'WETH',
  USDEX = "USDEX+",
  MFT = "MFT",
  USDC = "USDC",
  MANTA = "MANTA",
  CETO = "CETO",
  STGAI = "STGAI",
  BCETO = "bCETO",
  STONE = "STONE",
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
}

export interface Address {
  169?: string
}

export interface FarmConfig {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  tokenSymbol: string
  tokenAddresses: Address
  quoteTokenSymbol: QuoteToken
  quoteTokenAdresses: Address
  multiplier?: string
  isTokenOnly?: boolean
  isCommunity?: boolean
  risk: number
  imageSizes?: number[]
  reverseImage?: boolean,
  rehypothecated?: boolean,
  disabled?: boolean,
  v2?: boolean,
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
}

export interface PoolConfig {
  sousId: number
  image?: string
  tokenName: string
  stakingTokenName: QuoteToken
  stakingLimit?: number
  stakingTokenAddress?: string
  contractAddress: Address
  poolCategory: PoolCategory
  projectLink: string
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  tokenDecimals: number
}
