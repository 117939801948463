import React, { useState, useEffect, useContext } from 'react'

const SLOW_INTERVAL = 60000

const RefreshSlowContext = React.createContext({ slowRefresh: 0 })

export const useSlowRefresh = () => useContext(RefreshSlowContext)


const RefreshSlowContextProvider = ({ children }) => {
  const [slow, setSlow] = useState(0)

  useEffect(() => {
    const interval = setInterval(async () => {
      setSlow((prev) => prev + 1)
    }, SLOW_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  return <RefreshSlowContext.Provider value={{ slowRefresh: slow }}>{children}</RefreshSlowContext.Provider>
}

export { RefreshSlowContext, RefreshSlowContextProvider }
