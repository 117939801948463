import { AbiItem } from 'web3-utils'
import { getMasterChefAddress, getZapperAddress, getCetoBurnAddress, getBcetoAddress } from 'utils/addressHelpers'
import { ENV_CHAIN_ID } from 'config/constants'
import erc20 from 'config/abi/erc20.json'
import weth from 'config/abi/weth.json'
import contracts from 'config/constants/contracts'
import masterChef from 'config/abi/masterchef.json'
import zapper from 'config/abi/zapper.json'
import cetoBurn from 'config/abi/cetoBurn.json'
import bceto from 'config/abi/bceto.json'
import { makeContract } from '../utils/contract'
import useWeb3 from './useWeb3'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const { library } = useWeb3()

  return makeContract(library, erc20, address)
}

export const useWETH = () => {
  const { library } = useWeb3()

  return makeContract(library, weth, contracts.weth[ENV_CHAIN_ID])
}

export const useMasterchef = () => {
  const { library } = useWeb3()

  return makeContract(library, masterChef, getMasterChefAddress())
}

export const useCetoBurn = () => {
  const { library } = useWeb3()

  return makeContract(library, cetoBurn, getCetoBurnAddress())
}

export const useBcetoBurn = () => {
  const { library } = useWeb3()

  return makeContract(library, bceto, getBcetoAddress())
}

export const useZapperContract = () => {
  const { library } = useWeb3()

  return makeContract(library,zapper, getZapperAddress())
}
