import { Contract } from 'web3-eth-contract'

export const getAllowance = async (
  lpContract: Contract,
  masterChefContract: Contract,
  account: string,
): Promise<string> => {
  try {
    const allowance: string = await lpContract.methods.allowance(account, masterChefContract.options.address).call()
    return allowance
  } catch (e) {
    return '0'
  }
}

export const getTokenBalance = async (
  tokenContract: Contract,
  userAddress: string,
): Promise<string> => {
  try {
    const balance: string = await tokenContract.methods.balanceOf(userAddress).call()
    return balance
  } catch (e) {
    return '0'
  }
}

export const getDecimals = async (
  tokenContract: Contract
): Promise<string> => {
  try {
    const decimals: string = await tokenContract.methods.decimals().call()
    return decimals
  } catch (e) {
    return '18'
  }
}
