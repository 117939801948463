import React from 'react'
import { createRoot } from 'react-dom/client'
import { ResetCSS } from 'sparkswap-uikit'
import ConnectionContext from './contexts/ConnectionContext'
import Providers from './Providers'
import GlobalStyle from './style/Global'
import App from './App'


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <ConnectionContext>
      <Providers>
        <ResetCSS />
        <GlobalStyle />
        <App />
      </Providers>
    </ConnectionContext>
  </React.StrictMode>,
)

